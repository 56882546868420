import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import './MainLayout.less';

const { Header } = Layout;

const Logo = require('../assets/images/logo.svg').default;

const MainLayout = ({ children }) => {
  return (
    <Layout>
      <Layout>
        <Header>
          <div className="logo">
            <Link to="/">
              <Logo />
            </Link>
            <span>Liberdade financeira de verdade</span>
          </div>
        </Header>
        {children}
      </Layout>
    </Layout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainLayout;
