import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import MainLayout from './layout/MainLayout';
import RouteWithSubRoutes from './RouteWithSubRoutes';

const Home = lazy(() => import('pages/home/Home'));
const Signin = lazy(() => import('pages/signin/Signin'));
const Signup = lazy(() => import('pages/signup/Signup'));
const Economizometro = lazy(() => import('pages/economizometro/Economizometro'));

const routes = [
  {
    path: '/',
    component: Signin,
  },
  {
    path: '/signup',
    component: Signup,
  },
  {
    path: '/economizometro',
    component: Economizometro,
  },
];

const AppRouter = () => {
  return (
    <Router>
      <MainLayout>
        <Suspense
          fallback={
            <div className="lazy-loading">
              <LoadingOutlined />
{' '}
Loading...
</div>
          }
        >
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Suspense>
      </MainLayout>
    </Router>
  );
};

export default AppRouter;
